/****************************************
general
****************************************/
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  margin-top: 0;
  padding: 0;

  // @media (max-width: 992px) {
  //     font-size: 57.5%;
  // }

  // @media (max-width: 800px) {
  //     font-size: 55.5%;
  // }

  // @media (max-width: 768px) {
  //     font-size: 52.5%;
  // }

  // @media (max-width: 600px) {
  //     font-size: 50.5%;
  // }

  @media (max-width: 991px) {
    font-size: 58.5%;
  }

  @media (max-width: 800px) {
    font-size: 56.5%;
  }

  @media (max-width: 767px) {
    font-size: 53.5%;
  }

  @media (max-width: 600px) {
    font-size: 51.5%;
  }
}

body {
  font-family: "Spartan", sans-serif;
  box-sizing: border-box;

  overflow-x: hidden;
}

/****************************************
background pattern
****************************************/
.background-pattern {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 80%;
  height: 100%;

  &-img {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    z-index: 2;
    mix-blend-mode: color-burn;
    background-position: 0 center;
  }

  &-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    @include border-gradient-double(45deg);
    z-index: 1;
  }

  &-bottom {
    width: 100%;
    height: 15rem;
    position: absolute;
    bottom: -15rem;

    .image {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      z-index: -1;
    }
  }

  @media (max-width: 991px) {
    &-bottom {
      height: 5rem;
      bottom: -5rem;
      .image {
        height: 15rem;
        bottom: -5rem;
      }
    }
  }

  &-1 {
    .background-pattern-gradient {
      @include drop-shadow(
        $color: var(--gradient-green-rgba-2),
        $yOffset: 50px,
        $blurRadius: 50px
      );
    }
  }

  &-radius {
    @include border-radius(2rem);
    .background-pattern-img,
    .background-pattern-gradient {
      @include border-radius(2rem);
    }
  }

  &-radius-reverse {
    @include border-radius-reverse(2rem);
    .background-pattern-img,
    .background-pattern-gradient {
      @include border-radius-reverse(2rem);
    }
  }

  .background-pattern-img {
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: auto;
  }
  .background-loop {
    animation: loop 480s linear infinite;
  }

  @keyframes loop {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 8000% -1000%;
    }
  }
}

/****************************************
container
****************************************/
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: unset;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}
